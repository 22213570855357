/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

div,
nav,
main {
  box-sizing: border-box;
}


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

#mobileDownload{
  display: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

main {
  align-self: center;
  margin: 0 auto;
  width: 1580px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

nav {
  box-shadow: 0px 5px 5px rgb(165, 165, 165);

  z-index: 10;
  border-radius: 20px;
  background-color: #F2F2F2;
  width: 98%;
  max-width: 1628px;
  height: 92px;
  color: #000;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  position: fixed;
  align-items: center;
  top: 46px;
  padding: 15px 20px;

  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  left: 50%;
  transform: translate(-50%, 0);
}

nav ul {
  display: flex;
  flex-direction: row;
  align-items: center;
}

nav ul li {
  margin-right: 47px;
  transition: .1s;

}

nav ul li a {
  text-decoration: none;
  color: #000;
}

nav ul li a:hover {
  opacity: .6;
}

.LiWebVersion {
  margin-right: 0px;
}

.WebVersion {
  background-color: #000;
  height: 60px;
  width: auto;
  color: #fff;
  padding: 0 10px;
  border-radius: 7px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.logo img {
  margin-right: 16px;
}

.WebVersion img {
  margin-right: 8px;
}

.block1 {
  width: 1215px;
  height: auto;
  margin-top: 162px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #000;
  font-family: "Inter";
}

.block1 div {
  display: flex;
  flex-direction: column;
}

.block1 h1 {
  margin-top: 50px;
  font-size: 50.423px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
}

.block1 h1 img {
  width: 154px;
  height: 154px;
  margin-right: 28px;
  border-radius: 37.977px;
  background: #FFF;

  box-shadow: 5.042px 6.303px 17.648px 0px rgba(0, 0, 0, 0.25);
}

.block1 h2 {
  color: #000;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 55px;
}

.block1 h2 span {
  color: #000;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}


#download {
  margin-top: 142px;
  border-radius: 34px;
  background: #0057BE;
  width: 321px;
  height: 70px;
  text-align: center;
  display: flex;
  justify-content: center;
  color: #FFF;

  font-family: "Inter";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.block1 img {
  width: 357px;
  height: 675px;
}

.abonents {
  width: 1580px;
  height: 860px;
  flex-shrink: 0;
  background: #F2F2F2;
  border-radius: 20px;
  margin-top: 53px;

  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 38px;
  position: relative;
}

.abonents_info {
  width: 1450px;
  height: 90px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #FFF;
  margin-top: 17px;
}

.abonents_info ul li {
  display: flex;
  align-items: center;
}

.abonents_info ul {
  display: flex;
  justify-content: space-around;
  color: #000;
  font-family: "Inter";
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 100%;
}

.abonents_info ul span {
  color: #0057BE;

  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 32px;
}

.abonents_gromadi {
  display: flex;
  margin-top: 87px;
  justify-content: space-between;
  min-width: 100%;
  padding: 0 128px;
  box-sizing: border-box;
  flex: 1;
  position: relative;
}

.abonents_gromadi li {
  color: #000;
  font-family: "Inter";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 144.023%;
  display: flex;
  align-items: center;

}
a{
  text-decoration: none;
}
.abonents_gromadi img {
  margin-right: 23px;
}

.abonents h1 {
  color: #000;
  top: 46px;
  left: 62px;
  font-family: "Inter";
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: absolute;
}

.abonents_nav {
  width: 1450px;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}

.circle {
  width: 14px;
  height: 14px;
  border-radius: 50px;
  background-color: #9B9B9B;
  margin: 0 5px;
}

.modules {
  width: 1580px;
  margin-top: 95px;
}

.modules h1 {
  color: #000;

  font-family: "Inter";
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 64px;
}
.modules_info h1{
  margin-left: 4px;
}
.slider {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 64px;
}
.slider_blocks{
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  transition: .3s;
}
button{
  background-color: transparent;
  border: none;
}
button:hover{
  cursor: pointer;
}
.slider_hider{
  width: 100%;
  overflow: hidden;
}
.slider_hider_towns{
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slider_blocks_towns{
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  transition: .3s;
}
.arrow {
  width: 53px;
  height: 38px;
}

.modules_info {
  min-width:  100%;
  padding: 0 53px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  flex: 1;
  box-sizing: border-box;
}

.slide_logo {
  width: 100.548px;
  height: 99.681px;
  flex-shrink: 0;
  margin-top: 77px;
  right: 0;
  position: absolute;
}

.modules_info h1 {
  margin-top: 100px;
  text-transform: uppercase;
  max-width: calc(100% - 60px);
}
.modules_info_details{
  margin-left: 49px;
}
.modules_info_details ul {
  margin-top: 103px;
  color: #000;

  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  
}
.module_img1 {
  height: 710px;
}
.modules_info_details li {
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.circleImg {
  width: 28.604px;
  height: 28.604px;
  margin-right: 16px;
}

.conncect {
  width: 1580px;
  height: 843px;
  border-radius: 20px;
  background: #0057BE;
  margin-top: 70px;
}

.conncect h1 {
  color: #FFF;

  font-family: "Inter";
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 44px;
  margin-left: 65px;
}

.conncect_data {
  padding-left: 130px;
  margin-top: 68px;
  color: #FFF;
  font-family: "Inter";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: space-between;

  padding-right: 65px;
}

.conncect_data span {
  color: #FFF;

  font-family: "Inter";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.conncect_data_right h3 {
  color: #FFF;

  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 32px;
  margin-bottom: 9px;
}

.conncect_data_right input {
  width: 554px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 27px;
  background: #FFF;
  border: none;
  margin-bottom: 19px;
}

.conncect_data_right {
  display: flex;
  flex-direction: column;
}

.conncect_data_right button {
  width: 456.316px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 36.237px;
  background: #E9D200;
  border: none;
  align-self: center;
  margin-top: 25px;
  color: #000;

  font-family: "Inter";
  font-size: 32.211px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.conncect_data_left img {
  width: 109px;
  height: 109px;
  margin-right: 20px;
}

.copyright {
  color: #000;
  margin-top: 62px;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
  /* 14px */
}

.conncect_data_left h1 {
  display: flex;
  align-items: center;
  margin-top: 170px;
  margin-left: 0px;
}

.conncect_data_left {
  display: flex;
  flex-direction: column;
}

.conctacts {
  width: 100%;
  flex-shrink: 0;
  background: #F2F2F2;
  margin-top: 87px;
  display: flex;
  flex-direction: column;
}

.conctacts_bottom {
  width: 100%;
  height: 87px;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.conctacts_top {
  height: 484px;
  width: 1580px;
  padding: 39px 65px;
  align-self: center;
}

.conctacts_top h1 {
  color: #000;

  font-family: "Inter";
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.conctacts_bottom img {
  height: 50px;
  width: 360px;
}

.conctacts_top_data_left {
  margin-left: 65px;
}

.conctacts_top_data_left h2 {
  color: #000;
  font-family: "Inter";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 28px;
}

.conctacts_top_data_left p {
  color: #4D4D4D;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.conctacts_top_data_left h3 {
  color: #000;

  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 52px;
}

.conctacts_top_data {
  display: flex;
  padding-top: 72px;
  justify-content: space-between;
}

.conctacts_top_data_right h2 {
  color: #000;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  margin-bottom: 26px;
}

.conctacts_top_data_right img {
  margin-right: 25px;
}

.conctacts_top_data_right {
  display: flex;
  flex-direction: column;
}

.tgbutton {
  width: 393px;
  height: 58px;
  flex-shrink: 0;
  border-radius: 34px;
  color: #FFF;

  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
}

@media (max-width: 1600px) {
  nav {
    width: 90%;
  }

  main {
    width: 100%;
  }

  .block1 {
    width: 806px;
    height: auto;
  }

  .conncect {
    width: 1024px;
    border-radius: 12px;
    height: auto;
    padding-bottom: 82px;
  }

  .abonents {
    margin-top: 35px;
    width: 1024px;
    height: auto;
    border-radius: 12px;
  }

  .block1 img {
    width: 237.074px;
    height: 448.249px;
  }

  .block1 h1 img {
    width: 102.267px;
    height: 102.267px;
    border-radius: 25.219px;
    box-shadow: 3.348px 4.186px 11.72px 0px rgba(0, 0, 0, 0.25);
  }

  .block1 h2 {
    margin-top: 36px;
    font-size: 23.907px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

  }

  .block1 h2 span {
    font-size: 27.721px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  #download {
    margin-top: 94px;
    width: 213.167px;
    height: 46.485px;
    color: #FFF;

    font-family: Inter;
    font-size: 23.907px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .abonents_info {
    width: 962.905px;
    height: 59.767px;
    border-radius: 13.281px;
  }

  .ukraineimg {
    width: 466.843px;
    height: 272.27px;
  }

  .abonents h1 {
    top: 30px;
    left: 32px;
    font-size: 27.891px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .abonents_info ul {
    font-size: 23.907px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .abonents_info ul span {
    font-size: 31.875px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .abonents_gromadi img {
    height: 86.329px;
    margin-right: 15px;
  }

  .abonents_gromadi li {
    font-size: 21.25px;
    font-style: normal;
    font-weight: 400;
    line-height: 144.023%;
  }

  .abonents_gromadi {
    width: 90%;
  }

  .abonents_gromadi {
    margin-top: 50px;
  }

  .abonents_nav {
    width: 90%;
    margin-bottom: 12px;
  }

  .circle {
    height: 10px;
    width: 10px;
  }

  .arrow {
    width: 31.211px;
    height: 24px;
  }

  .modules {
    width: 1024px;
  }

  .modules h1 {
    margin-left: 30px;
    font-size: 27.891px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .module_img1 {
    height: 544.531px;
  }
  .modules_info{
    padding: 0 14px;
  }
  .modules_info h1{
    margin-left: 0;
    font-size: 21px;
  }
  .modules_info_details{
    margin-left: 4px;
  }
  .modules_info_details ul {
    margin-top: 84px;
    font-size: 19.922px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .circleImg {
    width: 18.995px;
    height: 18.995px;
    margin-right: 10px;
  }

  .slide_logo {
    width: 66.771px;
    height: 66.196px;
  }

  .modules_info_details li {
    margin-top: 20px;
  }

  .conncect h1 {
    font-size: 27.891px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 30px;
    margin-left: 30px;
  }

  .conncect_data {
    padding-left: 74px;
    padding-right: 32px;
  }

  .conncect_data {
    font-size: 26.563px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .conncect_data span {
    font-size: 26.563px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .conncect_data_right input {
    width: 367.896px;
    height: 33.204px;
    border-radius: 17.93px;
    margin-bottom: 12px;
  }

  .conncect_data_right h3 {
    font-size: 19.922px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 20px;
  }

  .conncect_data_right button {
    width: 303.027px;
    height: 33.204px;

    font-size: 21.39px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 17px;
  }

  .conncect_data_left img {
    width: 72.384px;
    height: 72.384px;
  }

  .conncect_data_left h1 {
    margin-top: 122px;
    margin-left: 0px;
  }

  .conncect_data_left h1 {
    font-size: 30.082px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .conctacts_bottom {
    height: 57px;
  }

  .conctacts_bottom img {
    height: 32px;
    width: 235px;
  }

  .conctacts_top {
    width: 1024px;
    padding: 0;
    height: 320px;
  }

  .conctacts_top h1 {
    margin-top: 25px;
    margin-left: 32px;
    font-size: 27.891px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .conctacts_top_data_left h2 {
    font-size: 21.25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 12px;
  }

  .conctacts_top_data {
    padding-top: 50px;
    padding-right: 30px;
  }

  .conctacts_top_data_left p {
    font-size: 13.281px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .conctacts_top_data_left h3 {
    margin-top: 33px;
  }

  .conctacts_top_data_right h2 {
    font-size: 15.938px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 18px;
  }

  .conctacts_top_data_right img {
    width: 37.852px;
    height: 37.852px;
  }

  .tgbutton {
    width: 260.981px;
    height: 38.516px;
    text-align: center;
    font-size: 13.281px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .tgbutton img {
    width: 26.563px;
    height: 26.563px;
  }

  nav {
    height: 56.659px;
    border-radius: 12.22px;
    font-size: 14.663px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    top: 34px;
  }

  .logo {
    font-size: 14.663px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .logo img {
    width: 37.845px;
    height: 37.845px;
  }

  .WebVersion {
    width: 112.688px;
    height: 36.513px;
    border-radius: 4.277px;
    font-size: 12.22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0;
  }

  .WebVersion img {
    margin-right: 6px;
    width: 25.182px;
    height: 25.182px;
  }

  nav ul li {
    margin-right: 28px;
  }

  .copyright {
    margin-top: 28px;
    font-size: 9.297px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
  }
}

@media (max-width: 1024px) {

  .abonents,
  .conncect {
    border-radius: 0;
  }
}

@media (max-width: 1023px) {
  main {
    max-width: 390px;
    width: 100%;
  }

  nav {
    background: none;
    box-shadow: none;
    width: auto;
    position: absolute;
    left: 92px;
  }

  nav img {
    box-shadow: 0px 2px 3px 0px rgb(197, 197, 197);
    border-radius: 10px;
  }

  nav ul {
    display: none;
  }

  .block1 h1 {
    display: none;
  }

  .block1 {
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 115px;
  }

  .block1 h2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
  }

  .block1 h2 span {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    /* 138.889% */
  }

  .block1 h2 {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }

  .block1 img {
    max-width: 181px;
    height: 343px;
    margin-top: 6px;
  }

  #download {
    display: none;
  }

  #mobileDownload {
    width: 198px;
    height: 44px;
    border-radius: 80px;
    background: #0057BE;
    color: #FFF;

    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 14px;
  }

  .abonents {
    width: 100%;
  }

  .ukraineimg {
    width: 100%;
    height: auto;
  }

  .abonents h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    top: 10px;
    left: 24px;
  }

  .abonents_info {
    width: 96%;
    height: 35px;
    border-radius: 5px;
  }

  .abonents_info ul {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .abonents_info ul span {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 8px;
  }

  .abonents_gromadi img {
    width: 38px;
    height: 44px;
  }

  .abonents_gromadi li {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 144.023%;
    display: flex;
    margin-top: 16px;
  }

  .abonents_gromadi {
    flex-wrap: wrap;
    margin: 4px 0 0 0;
    padding: 0;
  }

  .abonents_gromadi img {
    margin-right: 8px;
  }

  .arrow {
    height: 18px;
    width: auto;
  }

  .circle {
    height: 6px;
    width: 6px;
  }

  .modules {
    width: 100%;
  }

  .modules h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .slider {
    padding: 0;
  }

  .module_img1 {
    width: 160px;
    object-fit: contain;
    height: auto;
  }

  .modules h1 {
    margin-top: 0;
  }

  .modules_info_details ul {
    margin-top: 14px;
  }

  .modules_info_details li {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .modules_info {
    padding: 0;
  }

  .circleImg {
    width: 8px;
    height: 8px;
    margin-right: 6px;
  }

  .modules h1 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  .modules_info_details h1 {
    margin-left: 0px;
    margin-top: 22px;
  }

  .slide_logo {
    width: 24px;
    height: 24px;
    margin-top: 20px;
  }

  .modules_info_details li {
    margin-top: 10px;
  }

  .conncect {
    width: 100%;
    padding-bottom: 20px;
  }

  .conncect_data {
    flex-direction: column;
    margin: 0;
    padding: 0;
    margin-top: 30px;
    padding: 0 24px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .conncect h1 {
    margin-top: 10px;
    margin-left: 14px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .conncect_data span {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
  }

  .conncect_data_left img {
    width: 47px;
    height: 46px;
  }

  .conncect_data_left h1 {
    margin: 20px 0 0 0;
    font-size: 15.002px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
  }

  .conncect_data_right h3 {
    margin: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 10px;
    margin-top: 20px;
    margin-bottom: 8px;
  }

  .conncect_data_right input {
    width: 100%;
    height: 44px;
    border-radius: 80px;
    margin-bottom: 0px;
  }

  .conncect_data_right button {
    margin-top: 40px;
    height: 44px;
    width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .conctacts_top {
    height: auto;
    width: 100%;
  }

  .conctacts_top_data {
    flex-direction: column-reverse;
    width: 100%;
    padding: 0 24px;
  }

  .conctacts_top h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 24px;
    margin-bottom: 20px;
  }

  .conctacts_top_data_left {
    margin-left: 0px;
  }

  .conctacts_top_data_left h2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 60px;
  }

  .conctacts_top_data_left p {
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    /* 160% */
  }

  .conctacts_top_data_left h3 {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 20px;
  }

  .copyright {
    font-size: 8px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .conctacts_top_data_right h2 {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
  }

  .conctacts_top_data_right img {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  .tgbutton {
    width: 231px;
    height: 44px;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .tgbutton img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  .conctacts_bottom img {
    height: 24px;
    object-fit: contain;
  }
  .conctacts_bottom{
    height: 42px;
  }
}